/* eslint-disable sonarjs/no-duplicate-string */
// This file is used by our deployment pipeline. The placeholders will be replaced by actual values from
// variables in Github Environments.

import type { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  appUiUrl: 'https://dev.hs.squadbox.ai',
  appApiUrl: 'https://api.dev.hs.squadbox.ai',
  releaseVersion: 'squadbox-a00606c43336c6b5649db0796e77efb86e4b5d0c',
  refreshTokensTimerDue: +'300000',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Will be replaced by valid values during deployment
  speechToTextProvider: 'azure',
  azureSpeechToTextRegion: 'canadacentral',
  sentry: {
    dsn: 'https://c7b7c43c20b97fdf8360460bfe7e50ee@o936403.ingest.us.sentry.io/4508202231726080',
    environment: 'hs-dev',
    release: 'squadbox-a00606c43336c6b5649db0796e77efb86e4b5d0c',
  },
  launchDarkly: {
    envKey: '67250f10f67eab080218073a',
    context: {
      kind: 'multi',
      user: {
        anonymous: true,
      },
      client: {
        key: 'hs',
        name: 'hs',
      },
      device: {
        anonymous: true,
      },
      browser: {
        anonymous: true,
      },
    },
    options: {
      bootstrap: 'localStorage',
      application: {
        id: 'squadbox-ui-hs-dev',
        version: 'squadbox-a00606c43336c6b5649db0796e77efb86e4b5d0c',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Will be replaced by valid values during deployment
  defaultLanguage: 'en',
  assistantPromptMaxLength: +'4000',
  jiraFeedbackScriptUrl: 'https://halfserious.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/xghl7j/b/0/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-USJIRA_FEEDBACK_SCRIPT_URLcollectorId=0a213ab5',
};
